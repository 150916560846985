import React, { useEffect } from 'react'
import screenshot1 from '../assets/images/screenshots/IMG_6670.webp'
import screenshot2 from '../assets/images/screenshots/IMG_6673.webp'
import screenshot3 from '../assets/images/screenshots/IMG_6679.webp'
import screenshot4 from '../assets/images/screenshots/IMG_6671.webp'
import screenshot5 from '../assets/images/screenshots/IMG_6674.webp'
import screenshot6 from '../assets/images/screenshots/IMG_6676.webp'
import screenshot7 from '../assets/images/screenshots/IMG_6680.webp'

const $ = window.$

const Screenshots = React.memo(() => {
  useEffect(() => {
    const NaxosOptions = {
      loader: true,
      zoomControlDiv: null,
      rtl: false
    }

    if ($('.screenshot-slider').length > 0) {
      const $screenshot = $('.screenshot-slider')

      $screenshot.owlCarousel({
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 2
          },
          960: {
            items: 4
          }
        },
        responsiveClass: true,
        autoplay: true,
        autoplaySpeed: 1000,
        loop: true,
        margin: 30,
        dotsEach: 2,
        rtl: NaxosOptions.rtl
      })

      if ($screenshot.hasClass('zoom-screenshot')) {
        $screenshot.magnificPopup({
          delegate: 'a',
          type: 'image',
          closeOnContentClick: false,
          closeBtnInside: false,
          mainClass: 'mfp-with-zoom',
          image: { verticalFit: true },
          gallery: { enabled: true },
          zoom: {
            enabled: true,
            duration: 300, // Don't forget to change the duration also in CSS
            opener: function (element) {
              return element.find('img')
            }
          }
        })
      }
    }
  }, [])

  const screenies = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7
  ]

  const boxShadow = 'rgba(0, 0, 0, 0.1) 2px 4px 8px'

  const screenshots = screenies.map((screenshot, index) => (
    <div
      className='item'
      key={index}
      style={{ border: '10px solid transparent' }}
    >
      <a href={screenshot}>
        <img src={screenshot} alt='' style={{ boxShadow, borderRadius: 20 }} />
      </a>
    </div>
  ))

  return (
    <section id='screenshots' className='bg-grey'>
      {/* <!-- Container --> */}
      <div className='container'>
        {/* <!-- Section title --> */}
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-6'>
            <div className='section-title text-center'>
              <h3>App Screenshots</h3>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            {/* <!-- Carousel --> */}
            <div className='owl-carousel owl-theme screenshot-slider zoom-screenshot'>
              {screenshots}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Screenshots
