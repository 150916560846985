import React from 'react'
import ReactDOM from 'react-dom'
import './assets/library/bootstrap/css/bootstrap.min.css'
import './assets/css/style.css'
import './assets/css/media.css'
import './assets/colors/blue.css'
import './assets/library/fontawesome/css/all.min.css'
import './assets/library/linea/arrows/styles.css'
import './assets/library/linea/basic/styles.css'
import './assets/library/linea/ecommerce/styles.css'
import './assets/library/linea/software/styles.css'
import './assets/library/linea/weather/styles.css'
import './assets/library/animate/animate.css'
import './assets/library/owlcarousel/owl.carousel.min.css'
import './assets/library/slick/slick.css'
import './assets/library/magnificpopup/magnific-popup.css'

import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
