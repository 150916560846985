import React, { useState, useContext } from 'react'

export const ConfigContext = React.createContext()

const defaults = {
  contactEmail: 'hello@thingsy.app'
}

const ConfigProvider = ({ children }) => {
  const [config] = useState(defaults)

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}

export const useFlags = () => {
  const context = useContext(ConfigContext)
  return context
}

export default ConfigProvider
