import { initializeApp } from 'firebase/app'
import 'firebase/remote-config'
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

const firebaseConfig = {
  apiKey: 'AIzaSyBsilCSK0sjvgyPzIJ451yrgA1ex1nMLCU',
  authDomain: 'category-7cbd8.firebaseapp.com',
  databaseURL: 'https://category-7cbd8.firebaseio.com',
  projectId: 'category-7cbd8',
  storageBucket: 'category-7cbd8.appspot.com',
  messagingSenderId: '823554118854',
  appId: '1:823554118854:web:0151da9daed7f311ea33c7',
  measurementId: 'G-P8X3J5P3HJ'
}

export const app = initializeApp(firebaseConfig)

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6LcnEVQdAAAAAE_n5tkibUafZ43DPS9VfMY1iv-N'),
//   isTokenAutoRefreshEnabled: true
// })
//
