import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import Home from './pages/Home'
import ConfigProvider from './context/ConfigProvider'

function App () {
  return (
    <Router>
      <Switch>
        <Route path='/privacy'>
          <Privacy />
        </Route>
        <Route path='/terms'>
          <Terms />
        </Route>
        <Route path='/'>
          <ConfigProvider>
            <Home />
          </ConfigProvider>
        </Route>
      </Switch>
    </Router>
  )
}

export default App
