import React from 'react'
import ContactForm from '../compoonents/ContactForm'
import { useFlags } from '../context/ConfigProvider'

const Contact = React.memo(() => {
  const { contactEmail } = useFlags()

  return (
    <section id='contact'>
      {/* <!-- Container --> */}
      <div className='container'>
        {/* <!-- Section title --> */}
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-6'>
            <div className='section-title text-center'>
              <h3>Get In Touch</h3>
              <p>
                Have more questions? We'd love to hear from you! Send us a message and we'll get back to you soon.
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          {/* <!-- Contact info --> */}
          <div className='contact-info col-12 col-lg-4 res-margin'>
            <h5>
              <span className='icon icon-basic-mail' />
              Email Address
            </h5>
            <p>
              <a href='mailto:{contactEmail}'>{contactEmail}</a>
            </p>
          </div>

          {/* <!-- Contact form --> */}
          <div className='col-12 col-lg-8'>
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  )
})

export default Contact
