import React from 'react'
import screenshot from '../assets/images/screenshots/IMG_6673_framed.webp'
import Icon from 'react-eva-icons'

const getIcon = icon => (
  <div className='box-icon-eva'>
    <Icon
      fill='#6369D1'
      name={icon}
      size='xlarge' // small, medium, large, xlarge
    />
  </div>
)

const FeatureBox = ({ icon, title, detail }) => (
  <li>
    <div className='feature-box media'>
      {/* <!-- Box icon --> */}
      <div className='box-icon'>{getIcon(icon)}</div>

      {/* <!-- Box Text --> */}
      <div className='box-text media-body align-self-center align-self-md-start'>
        <h4>{title}</h4>
        <p>{detail}</p>
      </div>
    </div>
  </li>
)

const features = {
  keepTrack: {
    icon: 'compass-outline',
    title: 'Track Your Things',
    detail:
      "Tracking your things with Thingsy is simple. Take a photo, add a name, add a location. You're getting organized!"
  },
  find: {
    icon: 'search-outline',
    title: 'Find Your Things',
    detail:
      "Finding your things is easier than ever! Search for anything and find what you're looking for super fast!"
  },
  login: {
    icon: 'share-outline',
    title: 'Log in With Social Media',
    detail:
      "Don't want to remember another password? Log in to Thingsy with your social media account."
  },
  cloud: {
    icon: 'cloud-upload-outline',
    title: 'Your Things In The Cloud',
    detail:
      "Securely store all your things in the cloud so you don't have to worry about losing your data even if you lose your device."
  },
  sortAndFilter: {
    icon: 'funnel-outline',
    title: 'Sort And Filter',
    detail:
      'Need to get more specific? Easily sort and filter your things by location, tag, size and date.'
  },
  simple: {
    icon: 'heart-outline',
    title: 'Simple, Easy To Use',
    detail:
      'Keeping track of your things is hard. Thingsy is designed to be easy to use so you can start organizing right away!'
  }
}

const leftFeatures = [features.keepTrack, features.find, features.login]
const rightFeatures = [features.sortAndFilter, features.cloud, features.simple]

const leftContent = leftFeatures.map((data, index) => (
  <FeatureBox key={`feature-${index}`} {...data} />
))

const rightContent = rightFeatures.map((data, index) => (
  <FeatureBox key={`feature-${index}`} {...data} />
))

const Features = React.memo(() => {
  return (
    <section id='features'>
      {/* <!-- Container --> */}
      <div className='container'>
        {/* <!-- Section title --> */}
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-6'>
            <div className='section-title text-center'>
              <h3>Keep track of your things.</h3>
              <p>
                Do you forget where you stored your things? Well... go ahead!{' '}
                <br />
                With Thingsy, you can let the app remember.
              </p>
            </div>
          </div>
        </div>

        <div className='row d-flex align-items-center'>
          {/* <!-- Left --> */}
          <div className='col-12 col-md-6 col-lg-4'>
            <ul className='features-item'>
              {/* <!-- Feature box --> */}
              {leftContent}
            </ul>
          </div>

          {/* <!-- Center --> */}
          <div className='col-12 col-lg-4 d-none d-lg-block'>
            <div className='features-thumb text-center'>
              <img src={screenshot} alt='' />
            </div>
          </div>

          {/* <!-- Right --> */}
          <div className='col-12 col-md-6 col-lg-4'>
            <ul className='features-item'>
              {/* <!-- Feature box --> */}
              {rightContent}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Features
