import React, { useState, useEffect } from 'react'

const Input = props => {
  const {
    id,
    type = 'text',
    placeholder = '',
    value: propValue = '',
    onChange,
    error,
    wasValidated,
    ...rest
  } = props
  const [value, setValue] = useState(propValue)

  const onChangeHandler = ({ currentTarget: { value } }) => {
    setValue(value)
  }

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ id, value })
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const style = {}
  if (error) {
    style.border = '1px solid #EB5E55'
  }

  const classNames = ['form-control']

  if (wasValidated && !error) {
    classNames.push('is-valid')
  }

  return (
    <div>
      <input
        style={style}
        type={type}
        className={classNames.join(' ')}
        placeholder={placeholder}
        onChange={onChangeHandler}
        value={value}
        {...rest}
      />
      {error && <div className='invalid-feedback'>{error}</div>}
    </div>
  )
}

export default Input
