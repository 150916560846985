import React from 'react'

const Support = React.memo(() => {
  const faqs = [
    {
      q: 'Can I see Thingsy in action before purchasing it?',
      a: "Sure thing! Download the Thingsy App and start using it right away.  You can enter up to 10 items in \"guest\" mode or if you want to add more items but aren't ready to commit to a subscription you can sign up for a free trial offer, details in the app."
    },
    {
      q: 'How do I cancel my Thingsy Premium Subscription?',
      a: 'Thingsy uses the Apple App store to manage subscriptions. Go to the App Store on your device to manage your Thingsy Premium Subscription.'
    },
    {
      q: 'What devices can I use Thingsy with?',
      a: 'The Thingsy App is available only for the iPhone through the Apple App Store. There are no specific plans to support other devices at this time.'
    },
    {
      q: 'How does Thingsy handle my privacy?',
      a: 'Your privacy and the security of your data are very important. Pleaes refer to our privacy policy for more information.'
    }
  ]

  const faqsRendered = faqs.map(({ q, a }, index) => (
    <div key={index} className='card'>
      <div className='card-header' role='tab' id={`heading-${index}`}>
        <h5>
          <a
            className={index === 0 ? 'show' : 'collapsed'}
            data-toggle='collapse'
            href={`#collapse-${index}`}
            role='button'
            aria-expanded='false'
            aria-controls={`collapse-${index}`}
          >
            {q}
          </a>
        </h5>
      </div>

      <div
        id={`collapse-${index}`}
        className={`collapse ${index === 0 && 'show'}`}
        role='tabpanel'
        aria-labelledby='heading-{index}'
        data-parent='#accordion'
      >
        <div className='card-body'>
          <p>{a}</p>
        </div>
      </div>
    </div>
  ))

  return (
    <section id='support'>
      {/* <!-- Container --> */}
      <div className='container'>
        {/* <!-- Section title --> */}
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-6'>
            <div className='section-title text-center'>
              <h3>Frequently Asked Questions</h3>
              <p>
                Have questions about Thingsy? Here are answers to some common
                questions about subscriptions and using Thingsy.
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-lg-10 offset-lg-1'>
            {/* <!-- FAQ accordion --> */}
            <div id='accordion' role='tablist'>
              {/* <!-- Question 1 --> */}
              {faqsRendered}
            </div>
          </div>
        </div>

        <div className='empty-30' />

        <div className='row'>
          <div className='col-12 text-center'>
            <p className='mb-0'>
              Still have a question?{' '}
              <a href='#contact'>
                <strong>Ask your question here</strong>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Support
