import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import Input from '../compoonents/Input'
import Textarea from '../compoonents/Textarea'
import { getDatabase, ref, set } from 'firebase/database'
import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as SuccessImage } from '../assets/images/contact-form-success.svg'
import { ReactComponent as Spinner } from '../assets/images/oval.svg'
import { ReactComponent as RefreshIcon } from '../assets/images/corner-down-left.svg'
import { ReactComponent as ErrorImage } from '../assets/images/submit-error.svg'
import { app } from '../firebase'
import { useFlags } from '../context/ConfigProvider'

const FIELD_MAX_LENGTHS = {
  name: 70,
  email: 254,
  subject: 78,
  message: 4096
}

const ContactForm = () => {
  const database = getDatabase(app)
  const { contactEmail } = useFlags()
  const [wasValidated, setWasValidated] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState()
  const initialErrors = {
    name: false,
    email: false,
    subject: false,
    message: false
  }
  const [errors, setErrors] = useState(initialErrors)

  const initFormData = {
    name: '',
    email: '',
    subject: '',
    message: ''
  }
  const [formData, setFormData] = useState(initFormData)

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .required()
      .max(FIELD_MAX_LENGTHS.name),
    email: yup
      .string()
      .email()
      .required()
      .max(FIELD_MAX_LENGTHS.email),
    subject: yup
      .string()
      .required()
      .max(FIELD_MAX_LENGTHS.subject),
    message: yup
      .string()
      .required()
      .max(FIELD_MAX_LENGTHS.message)
  })

  const onSubmitHandler = async e => {
    try {
      e.preventDefault()

      setSubmitError(null)

      const isValid = await validateForm()

      if (isValid) {
        setIsSubmitting(true)
        const id = uuidv4()

        await set(ref(database, `forms/contact/${id}`), {
          id,
          date: new Date().toISOString(),
          ...formData
        })

        setIsSubmitting(false)
        setWasSubmitted(true)
      }
    } catch (error) {
      console.log(error.message)
      setSubmitError('Oops! Something went wrong.')
      setIsSubmitting(false)
      setWasSubmitted(true)
    }
  }

  const onChangeHandler = ({ id, value }) => {
    setFormData({
      ...formData,
      [id]: value
    })
  }

  const validateForm = async () => {
    setWasValidated(true)

    const isFormValid = await formSchema.isValid(formData, {
      abortEarly: false // Prevent aborting validation after first error
    })

    if (isFormValid) {
      setErrors({ initialErrors })
    } else {
      formSchema.validate(formData, { abortEarly: false }).catch(err => {
        const formErrors = err.inner.reduce((acc, error) => {
          return {
            ...acc,
            [error.path]: error.errors[0]
          }
        }, {})
        setErrors({ ...initialErrors, ...formErrors })
      })
    }

    return isFormValid
  }

  useEffect(() => {
    if (wasValidated) {
      validateForm()
    }
  }, [formData, wasValidated]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRefreshClick = () => {
    setWasSubmitted(false)
    setIsSubmitting(false)
    setWasValidated(false)
    setErrors({ initialErrors })
    setFormData(initFormData)
    setSubmitError(null)
  }

  return (
    <form
      className=''
      id='contact-form'
      method='post'
      noValidate
      onSubmit={onSubmitHandler}
    >
      {wasSubmitted && !submitError && (
        <div className='row justify-content-center'>
          <div className='d-flex flex-column align-items-center p-4'>
            <SuccessImage style={{ width: '200px', height: '200px' }} />
            <h4 style={{ color: '#6369D1' }}>Thank you for the message!</h4>
            <p className='text-center'>
              Your message has already arrived!
              <br />
              We'll contact you soon.
            </p>
            <div onClick={onRefreshClick}>
              <RefreshIcon className='refresh' />
            </div>
          </div>
        </div>
      )}

      {submitError && (
        <div className='row justify-content-center'>
          <div className='d-flex flex-column align-items-center p-4'>
            <ErrorImage
              className='mb-4'
              style={{ width: '200px', height: '200px' }}
            />
            <h4 className='form-submit-error'>{submitError}</h4>
            <p className='text-center m-3'>
              We didn't get your message. Please try and send your message again
              or email us at <a href='mailto:{contactEmail}'>{contactEmail}</a>.
            </p>
            <div onClick={onRefreshClick}>
              <RefreshIcon className='refresh' />
            </div>
          </div>
        </div>
      )}

      {!wasSubmitted && (
        <>
          {isSubmitting && (
            <div className='spinner'>
              <Spinner
                style={{ stroke: '#6369D1', width: '100px', height: '100px' }}
              />
            </div>
          )}
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='form-group mt-2'>
                <Input
                  id='name'
                  placeholder='Name'
                  onChange={onChangeHandler}
                  error={errors.name}
                  wasValidated={wasValidated}
                  maxLength={FIELD_MAX_LENGTHS.name}
                />
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <div className='form-group mt-2'>
                <Input
                  id='email'
                  placeholder='Email'
                  type='email'
                  onChange={onChangeHandler}
                  error={errors.email}
                  wasValidated={wasValidated}
                  maxLength={FIELD_MAX_LENGTHS.email}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 col-lg-12'>
              <div className='form-group mt-2'>
                <Input
                  id='subject'
                  placeholder='Subject'
                  onChange={onChangeHandler}
                  error={errors.subject}
                  wasValidated={wasValidated}
                  maxLength={FIELD_MAX_LENGTHS.subject}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 col-lg-12'>
              <div className='form-group mt-2'>
                <Textarea
                  id='message'
                  placeholder='Message'
                  onChange={onChangeHandler}
                  error={errors.message}
                  wasValidated={wasValidated}
                  maxLength={FIELD_MAX_LENGTHS.message}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 col-lg-12 mt-2'>
              <button
                type='submit'
                id='contact-submit'
                name='send'
                className='btn'
              >
                Send Message
              </button>
            </div>
          </div>
        </>
      )}
    </form>
  )
}

export default ContactForm
