import React, { useEffect } from 'react'
import brand from '../Brand.svg'
import brandInverted from '../BrandInverted.svg'

const $ = window.$

const Header = React.memo(() => {
  useEffect(() => {
    // Dropdown menu
    $('.dropdown').on('click', function () {
      $('.submenu').slideToggle('slow')
    })

    // Mobile menu open
    $('.menu-bar').on('click', function () {
      $('.main-menu-area').addClass('mobile-menu-open')
    })

    // Mobile menu close
    $('.close-button').on('click', function () {
      $('.main-menu-area').removeClass('mobile-menu-open')
    })
  }, [])

  return (
    <header id='top-page' className='header'>
      <div id='mainNav' className='main-menu-area animated'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-lg-2 d-flex justify-content-between align-items-center'>
              {/* <!-- Logo --> */}
              <div className='logo'>
                <a className='navbar-brand navbar-brand1' href='image.html'>
                  <img src={brand} height='40' alt='Thingsy' data-rjs='2' />
                </a>

                <a className='navbar-brand navbar-brand2' href='image.html'>
                  <img
                    src={brandInverted}
                    height='25'
                    alt='Thingsy'
                    data-rjs='2'
                  />
                </a>
              </div>

              {/* <!-- Burger menu --> */}
              <div className='menu-bar d-lg-none'>
                <span />
                <span />
                <span />
              </div>
            </div>

            <div className='op-mobile-menu col-lg-10 p-0 d-lg-flex align-items-center justify-content-end'>
              {/* <!-- Mobile menu --> */}
              <div className='m-menu-header d-flex justify-content-between align-items-center d-lg-none'>
                {/* <!-- Logo --> */}
                <a href='https://thingsy.app' className='logo'>
                  <img
                    src={brandInverted}
                    height='30'
                    alt='Thingsy'
                    data-rjs='2'
                  />
                </a>

                {/* <!-- Close button --> */}
                <span className='close-button' />
              </div>

              {/* <!-- Items --> */}
              <ul className='nav-menu d-lg-flex flex-wrap list-unstyled justify-content-center'>
                <li className='nav-item'>
                  <a
                    className='nav-link js-scroll-trigger active'
                    href='#top-page'
                  >
                    <span>Home</span>
                  </a>
                </li>

                <li className='nav-item'>
                  <a className='nav-link js-scroll-trigger' href='#features'>
                    <span>Features</span>
                  </a>
                </li>

                <li className='nav-item'>
                  <a className='nav-link js-scroll-trigger' href='#screenshots'>
                    <span>Screenshots</span>
                  </a>
                </li>

                <li className='nav-item'>
                  <a className='nav-link js-scroll-trigger' href='#support'>
                    <span>Support</span>
                  </a>
                </li>

                <li className='nav-item'>
                  <a className='nav-link js-scroll-trigger' href='#pricing'>
                    <span>Pricing</span>
                  </a>
                </li>

                <li className='nav-item'>
                  <a className='nav-link js-scroll-trigger' href='#contact'>
                    <span>Contact</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
})

export default Header
