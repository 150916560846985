import React from 'react'
import brand from '../Brand.svg'

const Footer = React.memo(() => {
  return (
    <footer>
      {/* <!-- Widgets --> */}
      <div className='footer-widgets'>
        <div className='container'>
          <div className='row'>
            {/* <!-- Footer logo --> */}
            <div className='col-12 col-md-6 col-lg-3 res-margin'>
              <div className='widget'>
                <p className='footer-logo'>
                  <img src={brand} height='50' alt='Thingsy' data-rjs='2' />
                </p>
                <p>
                  Keeping track of your things is hard. Thingsy was built so you can keep track of your things easily.
                </p>
              </div>
            </div>

            {/* <!-- Useful links --> */}
            <div className='col-12 col-md-6 col-lg-2 offset-lg-1 res-margin'>
              <div className='widget'>
                <h6>Useful Links</h6>

                <ul className='footer-menu'>
                  <li>
                    <a href='#support'>Support</a>
                  </li>
                  <li>
                    <a href='/privacy'>Privacy Policy</a>
                  </li>
                  <li>
                    <a href='/terms'>Terms &amp; Conditions</a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <!-- Product help --> */}
            <div className='col-12 col-md-6 col-lg-3 res-margin'>
              <div className='widget'>
                <h6>Product Help</h6>

                <ul className='footer-menu'>
                  <li>
                    <a href='#support'>FAQ</a>
                  </li>
                  <li>
                    <a href='#features'>Features</a>
                  </li>
                  <li>
                    <a href='#contact'>Feedback</a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <!-- Download --> */}
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='widget'>
                <h6>Download</h6>

                <div className='button-store'>
                  <a
                    href='https://apps.apple.com/us/app/thingsy-app/id1520324043'
                    className='custom-btn d-inline-flex align-items-center m-2 m-sm-0'
                  >
                    <i className='fab fa-apple' />
                    <p>
                      Download on<span>App Store</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Copyright --> */}
      <div className='footer-copyright'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              {/* <!-- Text --> */}
              <p className='copyright text-center'>
                Copyright © 2021 <a href='https://thingsy.app'>Thingsy</a>. All
                Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
})

export default Footer
