import React, { useEffect } from 'react'
import screenshot1 from '../assets/images/screenshots/banner-single.webp'
import banner1 from '../assets/images/banner/banner001-1920.webp'
import banner2 from '../assets/images/banner/banner002-1920.webp'
import banner3 from '../assets/images/banner/banner003-1920.webp'

const $ = window.$

const Banner = React.memo(() => {
  useEffect(() => {
    if ($('.banner.image-bg').length > 0) {
      $('.banner.image-bg').backstretch(
        'https://media.istockphoto.com/photos/organizing-and-cleaning-home-picture-id1216950782'
      )
    }

    //Slide background
    if ($('.banner.slide-bg').length > 0) {
      $('.banner.slide-bg').backstretch([banner1, banner2, banner3], {
        duration: 6000,
        fade: 750
      })
    }
  }, [])

  return (
    <section id='home' className='banner slide-bg'>
      {/* <!-- Container --> */}
      <div className='container'>
        <div className='row align-items-center'>
          {/* <!-- Content --> */}
          <div className='col-12 col-lg-6 res-margin'>
            {/* <!-- Banner text --> */}
            <div className='banner-text'>
              <h1
                className='wow fadeInUp'
                data-wow-offset='10'
                data-wow-duration='1s'
                data-wow-delay='0s'
              >
                Organize your things.
              </h1>

              <p
                className='wow fadeInUp'
                data-wow-offset='10'
                data-wow-duration='1s'
                data-wow-delay='0.3s'
              >
                Be the boss of your stuff and let Thingsy keep track of your
                things.
                <br />
                <strong>Try Thingsy for free!</strong>
              </p>

              <div
                className='button-store wow fadeInUp'
                data-wow-offset='10'
                data-wow-duration='1s'
                data-wow-delay='0.6s'
              >
                <a
                  href='https://apps.apple.com/us/app/thingsy-app/id1520324043'
                  className='custom-btn d-inline-flex align-items-center m-2 m-sm-0'
                >
                  <i className='fab fa-apple' />
                  <p>
                    Download on<span>App Store</span>
                  </p>
                </a>
              </div>
            </div>
          </div>

          {/* <!-- Image --> */}
          <div className='col-12 col-lg-6 d-flex justify-content-center'>
            <div
              className='banner-image wow fadeInUp'
              data-wow-offset='10'
              data-wow-duration='1s'
              data-wow-delay='0.3s'
            >
              <img
                className='bottomShadow'
                src={screenshot1}
                style={{ maxHeight: 550 }}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Wave effect --> */}
      <div className='wave-effect wave-anim'>
        <div className='waves-shape shape-one'>
          <div className='wave wave-one'></div>
        </div>

        <div className='waves-shape shape-two'>
          <div className='wave wave-two'></div>
        </div>

        <div className='waves-shape shape-three'>
          <div className='wave wave-three'></div>
        </div>
      </div>
    </section>
  )
})

export default Banner
