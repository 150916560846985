import React from 'react'

const Services = React.memo(() => {
  return (
    <section id='services'>
      {/* <!-- Container --> */}
      <div className='container'>
        {/* <!-- Section title --> */}
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-6'>
            <div className='section-title text-center'>
              <h3>How It Works?</h3>
              <p>
                Easily add your things to Thingsy. Add attributes like location,
                size and tags. Then use search to find where you stored your
                stuff!
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          {/* <!-- Service 1 --> */}
          <div
            className='col-12 col-lg-4 res-margin wow fadeInUp'
            data-wow-offset='10'
            data-wow-duration='1s'
            data-wow-delay='0'
          >
            <div className='service-single'>
              <div className='icon icon-basic-photo' />

              <h5>Add your items</h5>
              <p>
                Thingsy was designed for fast data entry. Adding all your items
                to Thingsy is quick and easy.
              </p>
            </div>
          </div>

          {/* <!-- Service 2 --> */}
          <div
            className='col-12 col-lg-4 res-margin wow fadeInUp'
            data-wow-offset='10'
            data-wow-duration='1s'
            data-wow-delay='0.3s'
          >
            <div className='service-single'>
              <div className='icon icon-basic-server2' />

              <h5>Location, size, tags</h5>
              <p>
                Describe your items using location, size and tags so you can
                easily find them later.
              </p>
            </div>
          </div>

          {/* <!-- Service 3 --> */}
          <div
            className='col-12 col-lg-4 res-margin wow fadeInUp'
            data-wow-offset='10'
            data-wow-duration='1s'
            data-wow-delay='0.6s'
          >
            <div className='service-single'>
              <div className='icon icon-basic-magnifier' />

              <h5>Search</h5>
              <p>
                When you are ready to find your things, use search and Thingsy
                will show you the results lightning fast!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Services
