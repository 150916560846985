import React from 'react'
import screenshot from '../assets/images/screenshots/horizontal-screenshot.webp'

const Pricing = React.memo(() => {
  return (
    <section id='pricing' className='bg-grey'>
      {/* <!-- Container --> */}
      <div className='container'>
        {/* <!-- Section title --> */}
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-6'>
            <div className='section-title text-center'>
              <h3>Pricing Plans</h3>
              <p>
                Thingsy has one convient pricing plan - store up to 2500 items with Thingsy Premium.  Download the app and start your free trial!
              </p>
            </div>
          </div>
        </div>

        <div className='row align-items-center justify-content-center pricing-plans'>
          {/* <!-- Plan 2 --> */}
          <div className='col-12 col-lg-4 res-margin'>
            <div className='price-table plan-popular mb-4 mb-sm-5 mb-lg-0'>
              <div className='icon icon-basic-heart' />
              <h3 className='plan-type'>Thingsy Premium Subscription</h3>
              <h2 className='plan-price'>$5.99/month</h2>

              <ul className='list-unstyled plan-list'>
                <li>Store up to <strong>2500 items</strong></li>
                <li>Store your data securely in the Cloud</li>
                <li>Search by name, tag, location and more</li>
                <li>Subscribe with Apple Pay</li>
                <li><strong>14-day Free Trial</strong></li>
              </ul>

              <a className='btn' href='https://apps.apple.com/us/app/thingsy-app/id1520324043'>
                Start free trial
              </a>

              <div className='card-ribbon'>
                <span>Free Trial</span>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile phone --> */}
        <div className='row'>
          <div
            className='col-12 col-lg-8 offset-lg-2 mobile-phone wow fadeInUp'
            data-wow-offset='10'
            data-wow-duration='1s'
            data-wow-delay='0.9s'
          >
            <img
              style={{ filter: 'drop-shadow(4px 8px 8px rgba(0,0,0, .3))' }}
              src={screenshot}
              alt=''
            />
          </div>
        </div>
      </div>
    </section>
  )
})

export default Pricing
