import React from 'react'
import screenshot from '../assets/images/screenshots/filter-by-tag-screen.webp'

const Overview = React.memo(() => {
  return (
    <section id='overview' className='bg-grey'>
      {/* <!-- Container --> */}
      <div className='container'>
        {/* <!-- Track time --> */}
        <div className='row'>
          {/* <!-- Content --> */}
          <div className='col-12 col-lg-6 offset-lg-1 order-lg-last res-margin'>
            {/* <!-- Section title --> */}
            <div className='section-title text-center text-lg-left'>
              <h3>Find your things. <em>Fast</em>.</h3>
              <p>Thingsy has multiple ways to help you get to what you're looking for quickly.</p>
            </div>

            {/* <!-- Items --> */}
            <div className='overview-item'>
              {/* <!-- Item 1 --> */}
              <div className='overview-box d-flex flex-wrap'>
                {/* <!-- Icon --> */}
                <div className='icon icon-basic-magnifier' />

                {/* <!-- Content --> */}
                <div className='content'>
                  <h6 className='font-weight-bold mb-2 mt-0'>Lightning fast search</h6>
                  <p>
                    Super accurate search results are returned lighting fast as you type.
                  </p>
                </div>
              </div>

              {/* <!-- Item 2 --> */}
              <div className='overview-box d-flex flex-wrap'>
                {/* <!-- Icon --> */}
                <div className='icon icon-ecommerce-sale' />

                {/* <!-- Content --> */}
                <div className='content'>
                  <h6 className='font-weight-bold mb-2 mt-0'>Tag your things</h6>
                  <p>
                    Use tags to quickly find items you've grouped together.
                  </p>
                </div>
              </div>

              {/* <!-- Item 3 --> */}
              <div className='overview-box d-flex flex-wrap'>
                {/* <!-- Icon --> */}
                <div className='icon icon-basic-folder-multiple' />

                {/* <!-- Content --> */}
                <div className='content'>
                  <h6 className='font-weight-bold mb-2 mt-0'>Find things with filters</h6>
                  <p>
                    Filter your items by location, size and even tag.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Image --> */}
          <div className='col-12 col-lg-5 order-lg-first text-sm-center'>
            <img src={screenshot} alt='' />
          </div>
        </div>
      </div>
    </section>
  )
})

export default Overview
