import React from 'react'
import '../App.css'
import Header from '../pageSections/Header'
import Banner from '../pageSections/Banner'
import Features from '../pageSections/Features'
import Services from '../pageSections/Services'
import Overview from '../pageSections/Overview'
import Screenshots from '../pageSections/Screenshots'
import Support from '../pageSections/Support'
import Pricing from '../pageSections/Pricing'
import Contact from '../pageSections/Contact'
import Footer from '../pageSections/Footer'

function Home () {
  return (
    <>
      <Header />
      <Banner />
      <Features />
      <Services />
      <Overview />
      <Screenshots />
      <Support />
      <Pricing />
      <Contact />
      <Footer />

      <a href='#top-page' className='to-top'>
        <div className='icon icon-arrows-up' />
      </a>
    </>
  )
}

export default Home
